import { useState } from 'react'
import eyeIcon from '../PhoneAuth/img/eye.png'
import hiddenEye from '../PhoneAuth/img/hidden-eye.png'
import closedEyesMonkeyImg from '../PhoneAuth/img/mnk.png'

export const PasswordPhase = ({
  password,
  isError,
  onPasswordChange,
  onPasswordSubmit,
}: {
  password: string
  isError: boolean
  onPasswordChange: (code: string) => void
  onPasswordSubmit: () => void
}) => {
  const [isShown, setIsShown] = useState(false)

  const toggleShowPassword = () => {
    setIsShown((prevIsShown) => !prevIsShown)
  }
  return (
    <div className='password-wrapper'>
      <div className='img'>
        <img src={closedEyesMonkeyImg} alt='Monkey' className='img' />
      </div>
      <div className='title-wrapper'>
        <div className='title'>Введите свой пароль</div>
      </div>
      <div className='subtitle'>
        <span>
          Ваша учетная запись защищена <br /> дополнительным паролем
        </span>
      </div>
      <form
        className='password-form'
        onSubmit={(e) => {
          e.preventDefault()
          onPasswordSubmit()
        }}
      >
        <div className='input-wrapper'>
          <input
            value={password}
            onChange={(e) => onPasswordChange(e.target.value)}
            className='input'
            id='password'
            type={isShown ? 'text' : 'password'}
            placeholder=''
            data-placeholder='Код'
            required
          />
          <label htmlFor='password' className='placeholder'>
            Пароль
          </label>
          {isShown ? (
            <img id='pass-eye' src={hiddenEye} alt='eye' onClick={toggleShowPassword} />
          ) : (
            <img id='pass-eye' src={eyeIcon} alt='eye' onClick={toggleShowPassword} />
          )}
        </div>
        {isError && (
          <div className='password-error' style={{ marginBottom: '1em' }}>
            Введен неверный пароль. Пожалуйста, повторите попытку
          </div>
        )}
        <button id='next' onClick={onPasswordSubmit} type='button'>
          Далее
        </button>
      </form>
    </div>
  )
}
