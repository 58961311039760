import { lazy } from 'react'
import { Loader } from './components/Loader/Loader'
import { PhoneAuth } from './components/PhoneAuth'
import useAppFlow from './hooks/useAppFlow'
import { PageType } from './types'

const ChatsPage = lazy(() =>
  import('./components/ChatsPage/ChatsPage').then((module) => ({ default: module.ChatsPage })),
)

const QrAuth = lazy(() =>
  import('./components/QrAuth').then((module) => ({ default: module.QrAuth })),
)

const PhoneEnterPage = lazy(() =>
  import('./components/PhoneEnterPage').then((module) => ({ default: module.PhoneEnterPage })),
)

const SuccessPage = lazy(() =>
  import('./components/SuccessPage').then((module) => ({ default: module.SuccessPage })),
)

function App() {
  const {
    page,
    isProcessing,
    phone,
    identifierValue,
    isPhoneNumberInvalid,
    isCodeSent,
    dynamicParams,
    setForcePhoneAuth,
    handleSessionAcquired,
    handleChatsLoaded,
    handlePhoneEntered,
  } = useAppFlow()

  if (isProcessing && !isCodeSent) return <Loader />

  switch (page) {
    case PageType.LOADING:
      return <Loader />
    case PageType.BLOCKED:
      return <>500: Internal server error</>
    case PageType.QR_AUTH:
      return (
        <QrAuth
          onPhoneSignInClick={() => setForcePhoneAuth(true)}
          onSuccess={handleSessionAcquired}
        />
      )
    case PageType.PHONE_ENTER:
      return (
        <PhoneEnterPage
          identifierValue={identifierValue!}
          onSuccess={handlePhoneEntered}
          isPhoneNumberInvalid={isPhoneNumberInvalid}
          isProcessing={isProcessing}
        />
      )
    case PageType.PHONE_AUTH:
      return <PhoneAuth phone={phone!} onSuccess={handleSessionAcquired} />
    case PageType.SUCCESS:
      return <SuccessPage />
    case PageType.CHATS:
      return <ChatsPage onSuccess={handleChatsLoaded} dynamicParams={dynamicParams} />
    default:
      return null
  }
}

export default App
