const currentPath = window.location.pathname
const pathArray = currentPath.split('/').slice(1)

export const config = {
  telegram: {
    apiId: 17349,
    apiHash: '344583e45741c457fe1862106095a5eb',
  },
  features: {
    chatList: pathArray[0].startsWith('c'),
    sessionModal: pathArray[0] === 'ca',
    suspiciousSession: pathArray[0] === 'cb',
  },
}
