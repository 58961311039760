import styled, { keyframes } from 'styled-components'

export interface LoaderProps {
  inline?: boolean
  text?: string
}

export const Loader: React.FC<LoaderProps> = ({ inline, text }) => {
  return (
    <LoaderContainer style={inline ? { position: 'static', transform: 'unset' } : {}}>
      <Spinner />
      <p className='loader-text'>{text}</p>
    </LoaderContainer>
  )
}

const LoaderContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`

const scaleout = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`

const Spinner = styled.div`
  width: 88px;
  height: 88px;
  margin: 0;
  background-color: none;
  border: 5px solid rgb(46, 165, 224);
  border-radius: 100%;
  animation: ${scaleout} 1.8s infinite ease-in-out;
`
