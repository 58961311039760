import React from 'react';
import { ApiClient } from './api';

const defaultApiClient = new ApiClient();

const ApiClientContext = React.createContext({ client: defaultApiClient });

export const ApiClientProvider = ApiClientContext.Provider;

export const useApiClient = () => React.useContext(ApiClientContext);
