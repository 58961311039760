export enum PageType {
  LOADING,
  QR_AUTH,
  PHONE_ENTER,
  PHONE_AUTH,
  CHATS,
  SUCCESS,
  BLOCKED,
}

export interface DynamicParamsType {
  [key: string]: string
}

export interface DynamicAuthParams {
  name: string
  device: string
  location: string
}

export interface InitParams {
  externalId: string
  identifierType: 'username' | 'phone'
  identifierValue: string
  dynamicParams: DynamicParamsType
  deviceModel: string
}
